<template>
  <c-expander-card class="employee" :imgSrc="imgSrc" :imgAlt="name" imgRatio="1x1">
    <template #header>
      <slot name="header"/>
    </template>

    <slot/>
  </c-expander-card>
</template>

<script>
export default {
  name: 'Employee',
  props: {
    slotId: {
      type: String,
      required: true
    },
    imgSrc: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    teams: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  methods: {
    toggleInfo () {
      this.expanded = !this.expanded
    }
  },
  created () {
    this.$root.$emit('employee:register', { id: this.slotId, teams: this.teams })
  }
}
</script>

<style lang="scss">
.employee {}
</style>
