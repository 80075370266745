<template>
  <c-grid class="employee-grid">
    <template #header>
      <div class="employee-grid-filter">
        <b-dropdown class="dropdown-filter" right>
          <template #button-content>
            <template v-if="filterValue === FILTER_ALL_KEY"><slot name="filter-all"/></template>
            <template v-else>{{ filterValue }}</template>
            <b-icon class="text-primary" icon="arrow-dropdown"/>
          </template>

          <b-dropdown-item v-for="item in filterItems" :key="item" :active="item === filterValue" @click="setFilter(item)">
            <template v-if="item === FILTER_ALL_KEY"><slot name="filter-all"/></template>
            <template v-else>{{ item }}</template>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <slot name="header"/>
    </template>

    <b-row class="employee-grid-row" cols="1" cols-sm="2" cols-md="3" cols-lg="4">
      <template v-for="sKey in employeeSlots">
        <b-col v-show="employees[sKey] && employees[sKey].show" :key="sKey" class="employee-grid-col">
          <slot :name="sKey"/>
        </b-col>
      </template>
    </b-row>

    <template #footer>
      <slot name="footer"/>
    </template>
  </c-grid>
</template>

<script>
const EXCLUDE_SCOPED_SLOTS = ['filter-all']
const FILTER_ALL_KEY = '$$all$$'

export default {
  name: 'EmployeeGrid',
  data () {
    return {
      registeredEmployees: [],
      FILTER_ALL_KEY,
      filterValue: FILTER_ALL_KEY
    }
  },
  computed: {
    employeeSlots () {
      return Object.keys(this.$scopedSlots || {})
        .filter(sKey => !EXCLUDE_SCOPED_SLOTS.includes(sKey))
    },
    filterItems () {
      return Array
        .from(new Set([FILTER_ALL_KEY].concat(this.registeredEmployees.map(e => e.teams).flat())))
        .sort()
    },
    employees () {
      return this.registeredEmployees
        .reduce((employees, e) => Object.assign(employees, {
          [e.id]: {
            show: this.filterValue === FILTER_ALL_KEY || e.teams.includes(this.filterValue)
          }
        }), {})
    }
  },
  methods: {
    registerEmployee ({ id = '', teams = [] }) {
      this.registeredEmployees.push({ id, teams })
    },
    setFilter (item) {
      this.filterValue = item
    }
  },
  created () {
    this.$root.$on('employee:register', this.registerEmployee)
  }
}
</script>

<style lang="scss">
$employee-grid-filter-gap: $spacer !default;

$employee-grid-col-gap: $grid-gutter-width !default;

.employee-grid {
  .employee-grid-filter {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $employee-grid-filter-gap;
  }

  .employee-grid-row {
    margin-top: $employee-grid-col-gap * -0.5;
    margin-bottom: $employee-grid-col-gap * -0.5;

    .employee-grid-col {
      padding-top: $employee-grid-col-gap * 0.5;
      padding-bottom: $employee-grid-col-gap * 0.5;
    }
  }
}
</style>
